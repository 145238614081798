import React from "react"
import { Navbar } from "../components/navbar.component"
import Layout from "../components/layout.component"
import { graphql, Link } from "gatsby"
import { NavbarBlog } from "../components/navbar.blog.component"
import { Background } from "../components/common/background-image.component"
import { PlainStructuredText } from "../components/common/plain-structured-text.component"
import { Thumbnail } from "../components/thumbnail.blog.component"
import { linkFragment, linkResolver } from "../link-resolver"
import { Slices } from "../components/slices.component"
import { Pagination } from "../components/common/pagination.component"
import SEO from "../components/common/seo.component"
import { getLangPrefix } from "../utils/langs"

const Blog = ({ data, pageContext }) => {
    
  const blog = data.prismic.blogMainPage;
  const posts = data.prismic.allBlogPages.edges;
  const featured = posts.find(post => post.is_featured_article);
  const categories = data.prismic.allBlogCategorys.edges;

  const { numPages, currentPage} = pageContext

  if (blog) {
    return (
      <Layout>
        <SEO
          title={blog.page_title || blog.title}
          description={blog.description || blog.text}
          keywords={blog.keywords}
          image={blog.preview_image}
          lang={blog._meta.lang}
        />
        <Navbar slides={[{}]} lang={blog._meta.lang}/>
        <section className="blog-section header header-light pb20">
          &nbsp;
        </section>
        <NavbarBlog lang={blog._meta.lang} items={categories}/>
        {featured ? (
          <Background image={featured.node.image} className="blog-banner">
            <div className="slider__wrap"/>
            <Link to={linkResolver(featured.node._meta)}>
              <div className="container">
                <div className="row">
                  <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                    <div className="blog-banner-header">
                      <small>Featured Article</small>
                      <br/>
                      <h2>
                        <PlainStructuredText
                          structuredText={featured.node.title}
                        />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Background>
        ) : null}
        <section className="blog-section pb50">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
                <div className="thumbnail-wrapper">
                  {posts ? posts.map(post => (
                    <Thumbnail post={post.node}/>
                  )) : null}
                </div>
              </div>
            </div>
          </div>
          {numPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              numPages={numPages}
              path={`${getLangPrefix(blog._meta.lang)}/blog`}
            />
          ) : null}

        </section>

        <Slices body={blog.body} large={true}/>
      </Layout>
    )
  }
  return null
}

Blog.fragments = [linkFragment]
export default Blog

export const query = graphql`
    query mainBlogPageQuery($lang: String!, $uid: String!) {
        prismic {
            blogMainPage(uid: $uid, lang: $lang) {
                page_title
                description
                keywords {
                    keyword
                }
                preview_image
                _meta {
                    uid
                    type
                    lang
                }
                text
                body {
                    ... on PRISMIC_BlogMainPageBodyPricing_plans {
                        type
                        label
                        fields {
                            price_units
                            plan_price
                            plan_name
                            plan_features
                            link_text
                            link_style
                            link {
                                ...link
                            }
                            is_free_plan
                        }
                        primary {
                            bg_image
                            bg_color
                            title
                            text
                        }
                    }
                    ... on PRISMIC_BlogMainPageBodyRich_text {
                        type
                        label
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_BlogMainPageBodyForm {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            form_script
                            form_url
                            text
                        }
                    }
                    ... on PRISMIC_BlogMainPageBodyFeature {
                        type
                        label
                        primary {
                            bg_image
                            bg_color
                            text
                            title
                        }
                        fields {
                            image
                            link_style
                            link_text
                            text
                            title
                            link {
                                ...link
                            }
                        }
                    }
                    ... on PRISMIC_BlogMainPageBodyBlock_with_text_and_image {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            min_height
                            image
                            link_style
                            link_text
                            link {
                                ...link
                            }
                            text
                            title
                        }
                    }
                }
            }
            allBlogCategorys(lang: $lang) {
                edges {
                    node {
                        title
                        _meta {
                            uid
                            type
                            lang
                        }
                    }
                }
            }
            allBlogPages(lang: $lang, sortBy: meta_firstPublicationDate_DESC) {
                edges {
                    node {
                        _meta {
                            uid
                            type
                            lang
                            firstPublicationDate
                        }
                        category {
                            ... on PRISMIC_BlogCategory {
                                title
                                _meta {
                                    uid
                                    type
                                    lang
                                }
                            }
                        }
                        image
                        title
                        description
                        is_featured_article
                        page_title
                        preview_image
                    }
                }
            }
        }
    }
`
